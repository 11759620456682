import { initializeApp } from 'firebase/app'
const { initializeAppCheck, ReCaptchaV3Provider } = require('firebase/app-check')
const firebaseConfig = {
  apiKey: 'AIzaSyCG9qkOTS3XQckxVgVY5OdcK65Rutc2ZyM',
  authDomain: 'hamaokadentalclinicapp.firebaseapp.com',
  projectId: 'hamaokadentalclinicapp',
  storageBucket: 'hamaokadentalclinicapp.appspot.com',
  messagingSenderId: '374255948491',
  appId: '1:374255948491:web:8a14bbc8e651bae3f5414e',
}
export const app = initializeApp(firebaseConfig)
// reCAPTCHA _SITE_KEY : '6Ld9LX8cAAAAAH0VgtxGxlPyHRKXRdFt8I33_u4H'
// reCAPTCHA _SECRET_KEY : '6Ld9LX8cAAAAAJcpRYpbwwIVyvIRBLyCW5XaORea'
//debug token : "894F3199-37BE-49D4-B9E2-B39E1BDCC542"
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6Ld9LX8cAAAAAH0VgtxGxlPyHRKXRdFt8I33_u4H'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true,
})
