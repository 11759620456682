/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { app } from '../base.js'
import Roles from '../../src/roles/Roles'
import 'firebase/app-check'
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signInWithPhoneNumber,
  signOut,
  sendEmailVerification,
} from 'firebase/auth'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { initializeApp } from 'firebase/app'
export const AuthContext = React.createContext()
// eslint-disable-next-line react/prop-types
export const AuthProvider = ({ children }) => {
  const firebaseConfig = {
    apiKey: 'AIzaSyCG9qkOTS3XQckxVgVY5OdcK65Rutc2ZyM',
    authDomain: 'hamaokadentalclinicapp.firebaseapp.com',
    projectId: 'hamaokadentalclinicapp',
    storageBucket: 'hamaokadentalclinicapp.appspot.com',
    messagingSenderId: '374255948491',
    appId: '1:374255948491:web:8a14bbc8e651bae3f5414e',
  }
  const app = initializeApp(firebaseConfig)
  const auth = getAuth(app)

  const { initializeAppCheck, ReCaptchaV3Provider } = require('firebase/app-check')
  //@see https://www.google.com/recaptcha/admin/site/478096765/settings
  // reCAPTCHA _SITE_KEY : '6Ld9LX8cAAAAAH0VgtxGxlPyHRKXRdFt8I33_u4H'
  // reCAPTCHA _SECRET_KEY : '6Ld9LX8cAAAAAJcpRYpbwwIVyvIRBLyCW5XaORea'
  //debug token : "894F3199-37BE-49D4-B9E2-B39E1BDCC542"
  const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider('6Ld9LX8cAAAAAH0VgtxGxlPyHRKXRdFt8I33_u4H'),
    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true,
  })

  const functions = getFunctions(app, 'asia-northeast1')
  const createUser = httpsCallable(functions, 'createUser')
  const setCustomClaims = httpsCallable(functions, 'setCustomClaims')
  const getUser = httpsCallable(functions, 'getUser')

  const [user, setUser] = useState(null)
  const [craim, setCraim] = useState({})
  const axios = require('axios').default
  const [isAuthenticating, setIsAuthenticating] = useState(true)

  // const { initializeAppCheck, ReCaptchaV3Provider } = require('firebase/app-check')
  // const appCheck = initializeAppCheck(app, {
  //   provider: new ReCaptchaV3Provider('6Ld9LX8cAAAAAH0VgtxGxlPyHRKXRdFt8I33_u4H'),
  //   isTokenAutoRefreshEnabled: true,
  // })

  // var admin = require("firebase-admin");

  // var serviceAccount = require("path/to/serviceAccountKey.json");

  // admin.initializeApp({
  //   credential: admin.credential.cert(serviceAccount)
  // });

  const login = async (email, password) => {
    await signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log('★UserCredential', userCredential)
        console.log('★userCredential.user.emailVerified', userCredential.user.emailVerified)
        //https://firebase.google.com/docs/reference/js/auth.usercredential
        if (!userCredential.user.emailVerified) {
          console.log('メール送信します。')
          const actionCodeSettings = {
            url: 'https://app.hamaoka-sika.or.jp',
            handleCodeInApp: false,
          }
          return sendEmailVerification(auth.currentUser, actionCodeSettings)
        } else {
          setIsAuthenticating(false)
          auth.currentUser.getIdToken(true)
          setUser(userCredential.user)
          getCustomCraim(auth)
        }
      })
      .then((res) => {
        console.log(res)
      })
      .catch((error) => {
        console.log('◆ Error signInWithEmailAndPassword', error)
        console.log('◆ ErrorCode signInWithEmailAndPassword', error.code)
        console.log('◆ ErrorMessage signInWithEmailAndPassword', error.message)
        throw new Error(error.code, error.message)
      })
  }
  const smsVerify = async (auth, phonenumber, appVerifier) => {
    return await signInWithPhoneNumber(auth, phonenumber, appVerifier)
      .then((confirmationResult) => {
        return confirmationResult
      })
      .catch((error) => {
        throw new Error(error.message)
      })
  }

  const signup = async (formData) => {
    console.log('Start axios : ', formData)
    let staffData = undefined
    let userCredential = undefined
    await axios({
      method: 'get',
      url: 'https://api.hamaoka-sika.or.jp/api/staffs/email/' + formData.email,
      responseType: 'json',
    })
      // .then((res) => {
      //   console.log('Then axios : ', res)
      //   staffData = {
      //     ...res.data.data[0],
      //     phoneNumber: '+81' + String(formData.phoneNumber).slice(1, 11),
      //   }

      //   return createUserWithEmailAndPassword(auth, formData.email, formData.password)
      // })
      .then((res) => {
        console.log('Then axios : ', res)
        staffData = {
          ...res.data.data[0],
          phoneNumber: '+81' + String(formData.phoneNumber).slice(1, 11),
          password: formData.password,
        }
        return createUser(staffData, appCheck)
      })
      .then((res) => {
        userCredential = res
        console.log('Then createUser : ', res)
        return setCustomClaims(staffData)
      })
      .then((res) => {
        return getUser()
      })
      .catch((e) => {
        throw e
      })
  }

  const signout = async (history) => {
    console.log('サインアウト処理開始')
    await signOut(auth)
      .then(() => {
        setUser(null)
        history.push('/Login')
      })
      .catch((error) => {
        // An error happened.
      })
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user)
      setIsAuthenticating(false)
      if (auth.currentUser) {
        getCustomCraim(auth)
      }
    })
    return () => unsubscribe()
  }, [auth])

  /**
   * firebaseのCustom Craimを取得しStateへセット
   * @param {*} auth
   */
  const getCustomCraim = (auth) => {
    auth.currentUser
      .getIdTokenResult()
      .then((idTokenResult) => {
        console.log(idTokenResult)
        setCraim({
          role: String(idTokenResult.claims.role)
            .split(',')
            .map((item) => {
              return Number(item)
            }),
          // occupation: idTokenResult.claims.occupation,
          occupation: String(idTokenResult.claims.occupation),
          // .split(',')
          // .map((item) => {
          //   return Number(item)
          // }),
        })
      })
      .catch((error) => {
        throw new Error(error)
      })
  }

  const values = {
    isAuthenticating,
    login: login,
    signup: signup,
    signout: signout,
    smsVerify: smsVerify,
    user,
    craim,
    auth,
    appCheck,
  }
  return <AuthContext.Provider value={values}>{!isAuthenticating && children}</AuthContext.Provider>
}
