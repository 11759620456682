/* eslint-disable no-unused-vars */
import React, { Component, Suspense } from 'react'
import { HashRouter, BrowserRouter, Route, Routes } from 'react-router-dom'
import PrivateRoute from './auth/PrivateRoute'
import { AuthProvider } from './auth/AuthProvider'
import { CSpinner } from '@coreui/react-pro'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons' //Solid
import { fab } from '@fortawesome/free-brands-svg-icons' //Brands
import { far } from '@fortawesome/pro-regular-svg-icons' //Regular
import { fal } from '@fortawesome/pro-light-svg-icons' //Light
import { fad } from '@fortawesome/pro-duotone-svg-icons' //Duotone
import './scss/style.scss'

library.add(fas, fab, far, fal, fad)
// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const RegisterPhonenumber = React.lazy(() =>
  import('./views/pages/registerPhonenumber/RegisterPhonenumber'),
)
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

class App extends Component {
  render() {
    return (
      <AuthProvider>
        <HashRouter>
          <Suspense fallback={<CSpinner color="primary" />}>
            <Routes>
              <Route exact path="/login" name="Login Page" element={<Login />} />
              <Route exact path="/register" name="Register Page" element={<Register />} />
              <Route
                exact
                path="/registerPhonenumber"
                name="Verify Phonenumber"
                element={<RegisterPhonenumber />}
              />
              <Route exact path="/404" name="Page 404" element={<Page404 />} />
              <Route exact path="/500" name="Page 500" element={<Page500 />} />
              <Route
                path="/"
                name="home"
                element={
                  <PrivateRoute>
                    <DefaultLayout />
                  </PrivateRoute>
                }
              ></Route>
              <Route path="*" name="Home" element={<DefaultLayout />} />
            </Routes>
          </Suspense>
        </HashRouter>
      </AuthProvider>
    )
  }
}
export default App
