import React, { useContext } from 'react'
import { Route, Routes, Navigate, Outlet } from 'react-router-dom'
import { AuthContext } from './AuthProvider'
import Login from '../views/pages/login/Login'
// eslint-disable-next-line react/prop-types
const PrivateRoute = ({ children }) => {
  const DefaultLayout = React.lazy(() => import('../layout/DefaultLayout'))
  //https://firebase.google.com/docs/reference/js/auth.user.md?hl=ja#user_interface
  const { user } = useContext(AuthContext)
  const comp = user ? DefaultLayout : Login
  // return <Route {...options} element={comp} user={user} />
  if (!user) {
    return <Navigate to="/login" replace />
  }
  return children
}

export default PrivateRoute
