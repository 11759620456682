import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CAlert,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
} from '@coreui/react-pro'
import { logo } from 'src/assets/brand/logo'
import { logoMark } from 'src/assets/brand/logoMark'
import CIcon from '@coreui/icons-react'
import { AuthContext } from '../../../auth/AuthProvider'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const Login = () => {
  const errorMessage = {
    'auth/user-not-found': 'ユーザー情報が見つかりません',
    'auth/wrong-password': 'ユーザー名またはパスワードが誤っています',
    'auth/too-many-requests': 'リクエストが多すぎます。お待ちください。',
  }
  // コンテキスト
  const { user, login } = useContext(AuthContext)

  // ログイン失敗フラグ
  const [loginFailed, setLoginFailed] = useState(false)

  // ログインエラーメッセージ
  const [loginErrorMessage, setLoginErrorMessage] = useState('')

  // モーダル表示フラグ
  const [modalVisible, setModalVisible] = useState(false)

  // モーダルメッセージ
  const [modalMessage, setModalMessage] = useState('')

  // history
  const hisotry = useNavigate()

  const onDismissHandler = () => {
    hisotry.push('/dashboard')
  }

  // submitハンドラー
  const handleOnSubmit = async (event) => {
    setLoginFailed(false)
    const { email, password } = event.target.elements
    event.preventDefault()

    // ログイン失敗フラグをリセット
    // setLoginFailed(false)

    try {
      await login(email.value, password.value)
        .then((res) => {
          console.log('res', res)
          setModalMessage('ログイン成功')
        })
        .catch((error) => {
          setLoginFailed(true)
          throw error
        })
    } catch (error) {
      setLoginErrorMessage(error.message)
    }
  }
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center mx-4">
          <CCol xs sm={12} md={8} xl lg={6} xxl={4}>
            <CIcon icon={logo} height={30} alt="Logo" className="mb-2" />
          </CCol>
        </CRow>
        <CRow className="justify-content-center">
          <CCol xs sm={12} md={8} xl lg={6} xxl={4}>
            <CCard className="p-4">
              <CCardBody>
                <CForm onSubmit={(values) => handleOnSubmit(values)}>
                  <p className="text-medium-emphasis">アカウントへサインイン</p>
                  <CInputGroup className="mb-1">
                    <CInputGroupText>
                      <FontAwesomeIcon icon={['fad', 'user']} />
                    </CInputGroupText>
                    <CFormInput placeholder="Username" autoComplete="username" id="email" />
                  </CInputGroup>
                  <CInputGroup className="mb-3">
                    <CInputGroupText>
                      <FontAwesomeIcon icon={['fad', 'lock-alt']} />
                    </CInputGroupText>
                    <CFormInput
                      type="password"
                      placeholder="Password"
                      autoComplete="current-password"
                      id="password"
                    />
                  </CInputGroup>
                  {loginFailed && <CAlert color="danger">{errorMessage[loginErrorMessage]}</CAlert>}
                  <CRow>
                    <CCol xs="12">
                      {/* className="btn btn-success d-block mx-auto text-white" */}
                      <CButton
                        color="success"
                        className="px-4 d-block mx-auto text-white"
                        type="submit"
                        size="sm"
                      >
                        サインイン&nbsp;
                        <FontAwesomeIcon icon={['fad', 'sign-in-alt']} />
                      </CButton>
                    </CCol>
                    <CCol xs="12">
                      <CButton color="link" className="px-4 mx-auto d-block">
                        <FontAwesomeIcon icon={['fad', 'question-circle']} />
                        パスワードをお忘れですか？
                      </CButton>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol xs="12">
                      <Link to="/register">
                        <CButton color="link" className="px-4 mx-auto d-block">
                          <FontAwesomeIcon icon={['fad', 'user-plus']} />
                          利用者登録
                        </CButton>
                      </Link>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        <CModal
          onDismiss={onDismissHandler}
          size="sm"
          visible={modalVisible}
          alignment="center"
          onClick={() => setModalVisible(false)}
        >
          <CModalHeader>
            <CModalTitle>ユーザー登録完了</CModalTitle>
            <button
              className="btn btn-close"
              aria-label="Close"
              onClick={() => setModalVisible(false)}
            ></button>
          </CModalHeader>
          <CModalBody>{modalMessage}</CModalBody>
          <CModalFooter>
            <CButton color="success" onClick={onDismissHandler} size="sm">
              Close
            </CButton>
          </CModalFooter>
        </CModal>
      </CContainer>
    </div>
  )
}
export default Login
